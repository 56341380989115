<template>
  <section id="stugan" class="py-5">
    <!-- <heading
      >Stugan är 121 kvadrat stor med tre sovrum, två badrum samt stora gemensamhetsytor med matrum
      och storstuga.</heading
    > -->

    <v-container style="max-width: 1168px">
      <v-container>
        <v-carousel cycle height="400" hide-delimiter-background show-arrows-on-hover class="mb-5">
          <v-carousel-item v-for="(slide, i) in slides" :key="i" :src="slide"> </v-carousel-item>
        </v-carousel>
        <p>{{ $t('cabin.intro') }}</p>
      </v-container>

      <v-container class="pt-5">
        <v-divider></v-divider>
        <h1>{{ $t('cabin.bedrooms-title') }}</h1>

        <v-row class="pt-5">
          <v-col v-for="(bedroom, i) in bedrooms" :key="i" class="d-flex" cols="12" sm="6" md="4">
            <v-card outlined>
              <v-img :src="bedroom.src" height="500" />
              <v-card-title
                class="align-start"
                style="min-height: 40px"
                v-text="getTranslation(bedroom.name) + ' ' + (i + 1)"
              />
              <v-card-text class="pb-5 pt-3" v-text="getTranslation(bedroom.text)" />
            </v-card>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <h1 class="pt-5">{{ $t('cabin.spaces') }}</h1>
        <v-row>
          <v-col cols="12" sm="8" md="8">
            <v-carousel
              cycle
              style="max-width: 650px"
              hide-delimiter-background
              show-arrows-on-hover
              class="mb-5"
            >
              <v-carousel-item
                v-for="(yta, i) in sallskapsytor"
                :key="i"
                :src="yta"
              ></v-carousel-item>
            </v-carousel>
          </v-col>
          <v-col>
            <ul>
              <li>
                {{ $t('cabin.social-items.1') }}
              </li>
              <li>{{ $t('cabin.social-items.2') }}</li>
              <li>{{ $t('cabin.social-items.3') }}</li>
              <li>{{ $t('cabin.social-items.4') }}</li>
              <li>{{ $t('cabin.social-items.5') }}</li>
              <li>{{ $t('cabin.social-items.6') }}</li>
              <li>{{ $t('cabin.social-items.7') }}</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-divider></v-divider>
        <h1 class="pt-5">{{ $t('cabin.bathrooms-title') }}</h1>
        <v-row>
          <v-col v-for="(washroom, i) in washrooms" :key="i" class="d-flex" cols="12" sm="6" md="4">
            <v-card outlined>
              <v-img :src="washroom.src" height="400" />
              <v-card-text class="pb-5 pt-3" v-text="getTranslation(washroom.text)" />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </section>
</template>

<script>
export default {
  metaInfo: {
    title: 'Stugan',
    meta: [
      {
        name: 'description',
        content: 'Mysig stuga på 121kvm med skogstomt i Ljusnedal, Funäsdalen',
      },
    ],
  },
  methods: {
    getTranslation(text) {
      return this.$i18n.t(text);
    },
  },

  components: {},

  data: () => ({
    bedrooms: [
      {
        name: 'cabin.bedroom',
        src: '/static/sovrum1.webp',
        text: 'cabin.bedroom1',
      },
      {
        name: 'cabin.bedroom',
        src: '/static/sovrum2.webp',
        text: 'cabin.bedroom2',
      },
      {
        name: 'cabin.bedroom',
        src: '/static/sovrum3.webp',
        text: 'cabin.bedroom3',
      },
    ],

    washrooms: [
      {
        name: 'Badrum 1',
        src: '/static/badrum4.webp',
        text: 'cabin.bathroom1',
      },
      {
        name: 'Badrum 2',
        src: '/static/badrum2.webp',
        text: 'cabin.bathroom2',
      },
    ],
    slides: ['/static/entre.webp', '/static/entre-sno2.webp', '/static/hangmatta2.webp'],
    sallskapsytor: [
      '/static/grill.webp',
      '/static/storstuga.webp',
      '/static/kitchen.webp',
      '/static/kitchen2.webp',
      '/static/sitgrupp.webp',
      '/static/kitchen_light.webp',
    ],
  }),
};
</script>
